<template>
  <!-- 触发历史 -->
  <div class="newSeeds roadNetwork">
    <div class="title">
      <div class="title-top" style="margin-bottom: 2vh">
        <!-- <div class="el-title-item">
          <el-form ref="form">
            <el-form-item label="节点编号">
              <el-input
                v-model="search"
                placeholder="请输入节点编号"
                @change="searching"
              ></el-input>
            </el-form-item>
          </el-form>
        </div> -->
      </div>
      <div class="title-bottom displayed">
        <div class="title-bottom-left">
          <el-button
            icon="el-icon-refresh-right"
            size="small"
            @click="searching"
            >刷新</el-button
          >
        </div>
        <div class="title-bottom-right">
          <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="searching"
            >搜索</el-button
          >
          <el-button icon="el-icon-refresh" size="small" @click="reset"
            >重置</el-button
          >
        </div>
      </div>
    </div>
    <el-table
      v-loading="loading"
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
    >
      <el-table-column
        show-overflow-tooltip
        label="序号"
        align="center"
        :index="indexMethod"
        width="100"
        type="index"
      >
      </el-table-column>
      <el-table-column
        label="设备名称"
        prop="deviceName"
        show-overflow-tooltip
        align="center"
      >
      </el-table-column>
      <el-table-column
        label="传感器名称"
        prop="sensorName"
        show-overflow-tooltip
        align="center"
      >
      </el-table-column>
      <el-table-column
        label="表达式"
        prop="exp"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span v-if="scope.row.exp == 'gt'">大于</span>
          <span v-if="scope.row.exp == 'lt'">小于</span>
          <span v-if="scope.row.exp == 'ge'">大于等于</span>
          <span v-if="scope.row.exp == 'le'">小于等于</span>
          <span v-if="scope.row.exp == 'eq'">等于</span>
        </template>
      </el-table-column>
      <el-table-column
        label="预警值"
        prop="warnValue"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column
        label="报警值"
        prop="warnData"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column
        label="报警时间"
        prop="createTime"
        show-overflow-tooltip
        align="center"
      ></el-table-column>

<!--      <el-table-column label="是否已处理" align="center">
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.hadSolve == 1"
            type="success"
            size="small"
            >已处理</el-button
          >
          <el-button
            v-else-if="scope.row.hadSolve == 0"
            type="primary"
            size="small"
            @click.native="changeState(scope.row)"
            >处理</el-button
          >
          <span v-else type="primary" size="small">暂无</span>
        </template>
      </el-table-column>-->
    </el-table>
    <div class="block pagingBox">
      <el-pagination
        :page-sizes="[8, 10, 20, 50]"
        :page-size="pageSize"
        :current-page="pageNow"
        layout="total, sizes, prev, pager, next, jumper"
        :total="dataTotal"
        @current-change="changePage"
        @size-change="changeSize"
      >
      </el-pagination>
    </div>

    <!-- 编辑 /  新建-->
    <el-dialog
      title="编辑河流"
      :visible.sync="editss"
      width="34vw"
      class="addAlameBox"
    >
      <div slot="title" class="header-title" style="position: relative">
        <span class="title-name"></span>
        <span class="title-age">{{ title }} </span>
        <span
          style="
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0px;
            font-size: 2.22vh;
          "
        >
        </span>
      </div>
      <div class="addAlaForm">
        <el-form
          ref="propsData"
          :model="propsData"
          :rules="rules"
          label-width="10vw"
        >
          <el-form-item label="单位名称" prop="data1">
            <el-input v-model="propsData.data1" :disabled="detailss"></el-input>
          </el-form-item>
          <el-form-item label="企业类型" prop="data2">
            <el-select v-model="propsData.data2" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="法人姓名">
            <el-input v-model="propsData.data7" :disabled="detailss"></el-input>
          </el-form-item>
          <el-form-item label="联系方式">
            <el-input v-model="propsData.data3" :disabled="detailss"></el-input>
          </el-form-item>
          <el-form-item label="地址">
            <el-input v-model="propsData.data4" :disabled="detailss"></el-input>
          </el-form-item>
          <el-form-item label="饲料生产许可证">
            <el-input v-model="propsData.data5" :disabled="detailss"></el-input>
          </el-form-item>
          <el-form-item label="经营范围">
            <el-input
              type="textarea"
              v-model="propsData.data6"
              :disabled="detailss"
            ></el-input>
          </el-form-item>
          <el-form-item size="large" v-show="showFlag" class="bigBtn">
            <el-button class="cancelBtn" type="info" @click="editss = false"
              >取消</el-button
            >
            <el-button class="saveBtn" type="primary" @click="onsubmit"
              >确认</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  /*eslint-disable */
  data() {
    return {
      tableData: [
        {
          orderNumber: "乐都市都区润田饲料厂",
          logiStatus: "袁存明",
          deliveTime: "1358474585",
          invoiceNo: "乐都区雨润镇羊泉村",
          people: "青饲证(2019)21022",
          people: "配合饲料,浓缩饲料生产,销售",
        },
      ],
      search: "",
      title: "",
      loading: false,
      editss: false,
      detailss: false,
      showFlag: false,
      pageSize: 10,
      pageNow: 1,
      dataTotal: 1,
      propsData: {},
      level: "",
      rules: {
        data1: [
          {
            required: true,
            message: "请输入名称",
            trigger: "blur",
          },
        ],
        data2: [
          {
            required: true,
            message: "请选择企业类型",
            trigger: "change",
          },
        ],
      },
      newFlag: false,
      options: [
        {
          value: "生产企业",
          label: "生产企业",
        },
        {
          value: "经营企业",
          label: "经营企业",
        },
      ],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    changeState(row) {
      // this.$post("/trigger/readDeviceTriggerHistory", {
      //   triggerId: row.id,
      // }).then((res) => {
      //   if (res.data.state == "success") {
      //     //console.log(res);
      //     this.$message.success("处理成功");
      //     this.getList();
      //   }
      // });
    },
    // async getList() {
    //   // this.loading = true;
    //   const { data } = await this.$get("/trigger/triggerHistoryList", {
    //     page: this.pageNow,
    //     size: this.pageSize,
    //     sensorCode: this.search,
    //   });
    //   this.tableData = data.datas;
    //   this.dataTotal = data.count;
    //   setTimeout(() => {
    //     this.loading = false;
    //   }, 500);
    // },
    async getList() {
      // this.loading = true;
      const { data } = await this.$get("/warnManage/pageWarnLogs", {
        page: this.pageNow,
        size: this.pageSize,
        // sensorCode: this.search,
      });
      this.tableData = data.datas;
      this.dataTotal = data.count;
      // this.clicks=true;
    },

    // 序号
    indexMethod(index) {
      return index + this.pageSize * (this.pageNow - 1) + 1;
    },
    // 新建
    add() {
      this.title = "添加饲料生产企业信息";
      this.editss = true;
      this.detailss = false;
      this.showFlag = true;
      this.newFlag = true;
      this.propsData = {};
    },
    // 详情
    details(row) {
      this.editss = true;
      this.showFlag = false;
      this.title = "饲料生产企业信息详情";

      this.detailss = true;
      this.propsData = JSON.parse(JSON.stringify(row));
    },
    // 编辑
    edits(row) {
      this.showFlag = true;

      this.title = "编辑饲料生产企业信息";
      this.editss = true;
      this.propsData = JSON.parse(JSON.stringify(row));
      this.detailss = false;
      this.newFlag = false;
    },
    // 新建 / 编辑 提交
    onsubmit() {
      this.$refs.propsData.validate((valid) => {
        if (valid) {
          if (this.newFlag == true) {
            this.$post("/monitor/insert", {
              id: 0,
              type: 107,
              data1: this.propsData.data1,
              data2: this.propsData.data2,
              data3: this.propsData.data3,
              data4: this.propsData.data4,
              data5: this.propsData.data5,
              data6: this.propsData.data6,
              data7: this.propsData.data7,
            }).then((res) => {
              if (res.data.state == "success") {
                this.$message.success(res.data.data);
                this.editss = false;
                this.getList();
              } else {
                this.$message.error(res.data.data);
                this.editss = false;
              }
            });
          } else if (this.newFlag == false) {
            this.$post("/monitor/update", {
              id: this.propsData.id,
              type: 107,
              data1: this.propsData.data1,
              data2: this.propsData.data2,
              data3: this.propsData.data3,
              data4: this.propsData.data4,
              data5: this.propsData.data5,
              data6: this.propsData.data6,
              data7: this.propsData.data7,
            }).then((res) => {
              if (res.data.state == "success") {
                this.$message.success(res.data.msg);
                this.editss = false;
                this.getList();
              } else {
                this.$message.error(res.data.msg);
                this.editss = false;
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    reset() {
      this.search = "";
      this.level = "";
      this.level1 = "";
      this.getList();
    },
    searching() {
      this.pageNow = 1;
      this.getList();
    },
    // 删除
    deletes(id) {
      // 弹出提示是否删除
      this.$confirm("此操作将永久删除该记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$get("/trigger/delTriggerHistory", {
            id: id,
          }).then((res) => {
            if (res.data.state == "success") {
              this.$message.success("删除成功");
              if (this.pageSize == this.dataTotal * 1 - 1) {
                this.pageNow = 1;
              }
              this.getList();
            } else {
              this.$message.error("删除失败");
            }
          });
        })
        .catch((e) => e);
    },

    // 更换每页数据
    changeSize(size) {
      this.pageSize = size;
      this.pageNow = 1;
      this.getList();
    },
    // 更换页码
    changePage(newpage) {
      this.pageNow = newpage;
      this.getList();
    },
  },
};
</script>

<style scoped lang="less">
@import "../../../style/backTable.less";
</style>
<style scoped src="../../../style/commonElement.css">
</style>
<style scoped>
.newSeeds {
  padding: 4vh 2vw !important;
  box-sizing: border-box;
  height: 100%;
}
.newSeeds /deep/ .el-input__inner:hover {
  border-color: #07ca88;
}
.newSeeds /deep/ .el-input__inner:focus {
  border-color: #07ca88;
}
.roadNetwork /deep/ .el-table td.is-center,
.roadNetwork /deep/ .el-table th.is-center {
  text-align: left;
}
.roadNetwork /deep/ .el-table {
  max-height: 70vh;
  overflow-y: scroll;
}

.roadNetwork /deep/ .el-table th {
  background: #fafafa;
  box-shadow: 1px 1px 2px #fafafa;
}
.roadNetwork /deep/ .el-table th > .cell:nth-of-type(-n + 1) {
  border-right: 1px solid #ebeef5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bigBtn /deep/.el-form-item__content {
  margin-left: 0 !important;
  text-align: center;
}
</style>